<template>
  <div class="pt-layout" role="group">
    <HeaderBasic :logo="LOGO_TIERRA" />
    <div
      :class="['pt-layout__content']"
      aria-live="polite"
      aria-atomic="true"
      aria-relevant="additions"
    >
      <slot />
    </div>
    <Footer
      v-if="common"
      :logo="LOGO_TIERRA"
      :highlight-text="common.footer_highlight_text"
      :menu-footer="common.menu_footer"
      :menu-legal="common.footer_legal"
      :menu-social-network="common.menu_social_networks"
      :menu-subscription="{
        ...common.footer_newsletter,
        footer_logos: [...common.footer_logos],
        popup: common.footer_newsletter_popup
      }"
    />
  </div>
</template>

<script>
import { LOGO_TIERRA } from '~/utils/enums';
export default {
  name: 'PtInvitationLayout',
  data() {
    return {
      LOGO_TIERRA,
      loading: false
    };
  },
  computed: {
    common() {
      return {
        ...this.$store.getters['strapi/getByField']('common')
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.pt-layout {
  background-color: $white;
  display: block;
  &__content {
    &.alternative {
      padding-top: $header-height;
    }
    &:not(&.alternative) {
      @media (min-width: 768px) {
        padding-top: $padding-main-content;
      }
    }
  }
  nav {
    @apply flex justify-between items-center py-2 px-10 fixed w-full bg-white shadow-lg;
    .btn-refresh {
      @apply px-4 py-2 bg-blue text-blue-dark rounded-lg hover:bg-blue-dark hover:text-white;
    }
  }
}
</style>
